export default [
  {
    path: '/reports/company-service-report',
    name: 'CompanyServiceReport',
    component: () => import('@/views/Reports/Company_reports/index.vue'),
    meta: {
      pageTitle: 'Genel Servis Raporu',
      breadcrumb: [
        {
          text: 'Raporlar',
          active: false,
        },
        {
          text: 'Genel Servis Raporu',
          active: true,
        },
      ],
      resource: 'REPORT_COMPANY_SERVICE',
      action: 'read',
    },
  },
]
