// import axios from '@/libs/axios'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    customers: [],
    customersCount: 0,
    customer: {},
    customerSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getCustomers(state) {
      return state.customers
    },
    getCustomersCount(state) {
      return state.customersCount
    },
    getCustomer(state) {
      return state.customer
    },
    getCustomerSaveStatus(state) {
      return state.customerSave
    },
  },
  mutations: {
    CUSTOMERS_LIST(state, data) {
      state.customers = data
    },
    CUSTOMER_VIEW(state, data) {
      state.customer = data
    },
    CUSTOMERS_COUNT(state, data) {
      state.customersCount = data
    },
    CUSTOMER_SAVE(state, data) {
      state.customerSave = data
    },
  },
  actions: {
    customersList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/customers', data)
        .then(response => {
          commit('CUSTOMERS_LIST', response.data.data)
          commit('CUSTOMERS_COUNT', response.data.count)
          commit('CUSTOMER_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    customerView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'customers.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/customers', data)
        .then(response => {
          commit('CUSTOMER_VIEW', response.data.data)
          commit('CUSTOMER_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    customerSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/customers/saveData', data)
        .then(response => {
          commit('CUSTOMER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
