// import axios from '@/libs/axios'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    customerAddresses: [],
    customerAddressesCount: 0,
    customerAddress: {},
    customerAddressSave: {
      message: null,
      status: null,
      id: null,
    },
    customerAddressRemove: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getCustomerAddresses(state) {
      return state.customerAddresses
    },
    getCustomerAddressesCount(state) {
      return state.customerAddressesCount
    },
    getCustomerAddress(state) {
      return state.customerAddress
    },
    getCustomerAddressSaveStatus(state) {
      return state.customerAddressSave
    },
    getCustomerAddressRemoveStatus(state) {
      return state.customerAddressRemove
    },
  },
  mutations: {
    CUSTOMER_ADDRESSES_LIST(state, data) {
      state.customerAddresses = data
    },
    CUSTOMER_ADDRESS_VIEW(state, data) {
      state.customerAddress = data
    },
    CUSTOMER_ADDRESSES_COUNT(state, data) {
      state.customerAddressesCount = data
    },
    CUSTOMER_ADDRESS_SAVE(state, data) {
      state.customerAddressSave = data
    },
    CUSTOMER_ADDRESS_REMOVE(state, data) {
      state.customerAddressRemove = data
    },
  },
  actions: {
    customerAddressesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/customer_address', data)
        .then(response => {
          commit('CUSTOMER_ADDRESSES_LIST', response.data.data)
          commit('CUSTOMER_ADDRESSES_COUNT', response.data.count)
          commit('CUSTOMER_ADDRESS_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    customerAddressView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'customer_address.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/customer_address', data)
        .then(response => {
          commit('CUSTOMER_ADDRESS_VIEW', response.data.data)
          commit('CUSTOMER_ADDRESS_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    customerAddressSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/customer_address/saveData', data)
        .then(response => {
          commit('CUSTOMER_ADDRESS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    customerAddressRemove({ commit }, addressId) {
      const data = JSON.stringify({
        id: addressId,
      })
      axiosIns
        .post('/customer_address/deleteData', data)
        .then(response => {
          commit('CUSTOMER_ADDRESS_REMOVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
