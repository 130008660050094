import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    report: {
      serviceWorkTime: {
        duration: 0,
      },
      ServiceCount: 0,
      MachinesCount: 0,
    },
  },
  getters: {
    getReport(state) {
      return state.report
    },
  },
  mutations: {
    REPORT_VIEW(state, data) {
      state.report = data
    },
    RESET_REPORT(state, data) {
      state.report = {
        serviceWorkTime: {
          duration: 0,
        },
        ServiceCount: 0,
        MachinesCount: 0,
      }
    },
  },
  actions: {
    setReport({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post('/reports/customer_service_report/customerservicereport', data)
        .then(response => {
          commit('REPORT_VIEW', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },

  },
}
