import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    smtpconfig: {},
    smtpconfigSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getSmtpconfig(state) {
      return state.smtpconfig
    },
    getSmtpconfigSaveStatus(state) {
      return state.smtpconfigSave
    },
  },
  mutations: {
    SMTPCONFIG_VIEW(state, data) {
      state.smtpconfig = data
    },
    SMTPCONFIG_SAVE(state, data) {
      state.smtpconfigSave = data
    },
  },
  actions: {
    smtpconfigView({ commit }) {
      axiosIns
        .post('/core/smtpconfig')
        .then(response => {
          commit('SMTPCONFIG_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    smtpconfigSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/core/smtpconfig/saveData', data)
        .then(response => {
          commit('SMTPCONFIG_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
