export default [
  {
    path: '/cities',
    name: 'Cities',
    component: () => import('@/views/Defines/Cities/Index.vue'),
    meta: {
      pageTitle: 'Şehir',
      breadcrumb: [
        {
          text: 'Şehir',
          active: true,
        },
      ],
      resource: 'CITIES_LIST',
      action: 'read',
    },
  },
  {
    path: '/cities/add',
    name: 'CitiesAdd',
    component: () => import('@/views/Defines/Cities/Add.vue'),
    meta: {
      pageTitle: 'Şehir',
      breadcrumb: [
        {
          text: 'Şehir',
          to: '/cities',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'CITIES_ADD',
      action: 'read',
    },
  },
  {
    path: '/cities/edit/:id',
    name: 'CitiesEdit',
    component: () => import('@/views/Defines/Cities/Edit.vue'),
    meta: {
      pageTitle: 'Şehir',
      breadcrumb: [
        {
          text: 'Şehir',
          to: '/cities',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'CITIES_EDIT',
      action: 'read',
    },
  },
  {
    path: '/districts',
    name: 'Districts',
    component: () => import('@/views/Defines/Districts/Index.vue'),
    meta: {
      pageTitle: 'İlçe',
      breadcrumb: [
        {
          text: 'İlçe',
          active: true,
        },
      ],
      resource: 'DISTRICTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/districts/add',
    name: 'DistrictsAdd',
    component: () => import('@/views/Defines/Districts/Add.vue'),
    meta: {
      pageTitle: 'İlçe',
      breadcrumb: [
        {
          text: 'İlçe',
          to: '/districts',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DISTRICTS_ADD',
      action: 'read',
    },
  },
  {
    path: '/districts/edit/:id',
    name: 'DistrictsEdit',
    component: () => import('@/views/Defines/Districts/Edit.vue'),
    meta: {
      pageTitle: 'İlçe',
      breadcrumb: [
        {
          text: 'İlçe',
          to: '/districts',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DISTRICTS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/machine_statuses',
    name: 'MachineStatuses',
    component: () => import('@/views/Defines/Machine_statuses/Index.vue'),
    meta: {
      pageTitle: 'Makine Durumları',
      breadcrumb: [
        {
          text: 'Makine Durumları',
          active: true,
        },
      ],
      resource: 'MACHINE_STATUSES_LIST',
      action: 'read',
    },
  },
  {
    path: '/machine_statuses/add',
    name: 'MachineStatusesAdd',
    component: () => import('@/views/Defines/Machine_statuses/Add.vue'),
    meta: {
      pageTitle: 'Makine Durumları',
      breadcrumb: [
        {
          text: 'Makine Durumları',
          to: '/machine_statuses',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'MACHINE_STATUSES_ADD',
      action: 'read',
    },
  },
  {
    path: '/machine_statuses/edit/:id',
    name: 'MachineStatusesEdit',
    component: () => import('@/views/Defines/Machine_statuses/Edit.vue'),
    meta: {
      pageTitle: 'Makine Durumu',
      breadcrumb: [
        {
          text: 'Makine Durumları',
          to: '/machine_statuses',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'MACHINE_STATUSES_EDIT',
      action: 'read',
    },
  },
  {
    path: '/invoiced_statuses',
    name: 'InvoicedStatuses',
    component: () => import('@/views/Defines/Invoiced_statuses/Index.vue'),
    meta: {
      pageTitle: 'Ücretlendirme',
      breadcrumb: [
        {
          text: 'Ücretlendirme',
          active: true,
        },
      ],
      resource: 'INVOICED_LIST',
      action: 'read',
    },
  },
  {
    path: '/invoiced_statuses/add',
    name: 'InvoicedStatusesAdd',
    component: () => import('@/views/Defines/Invoiced_statuses/Add.vue'),
    meta: {
      pageTitle: 'Ücretlendirme',
      breadcrumb: [
        {
          text: 'Ücretlendirme',
          to: '/invoiced_statuses',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'INVOICED_ADD',
      action: 'read',
    },
  },
  {
    path: '/invoiced_statuses/edit/:id',
    name: 'InvoicedStatusesEdit',
    component: () => import('@/views/Defines/Invoiced_statuses/Edit.vue'),
    meta: {
      pageTitle: 'Ücretlendirme',
      breadcrumb: [
        {
          text: 'Ücretlendirme',
          to: '/invoiced_statuses',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'INVOICED_EDIT',
      action: 'read',
    },
  },
  {
    path: '/machine_models',
    name: 'MachineModels',
    component: () => import('@/views/Defines/Machine_models/Index.vue'),
    meta: {
      pageTitle: 'Makine Modelleri',
      breadcrumb: [
        {
          text: 'Makine Modelleri',
          active: true,
        },
      ],
      resource: 'MACHINE_MODELS_LIST',
      action: 'read',
    },
  },
  {
    path: '/machine_models/add',
    name: 'MachineModelsAdd',
    component: () => import('@/views/Defines/Machine_models/Add.vue'),
    meta: {
      pageTitle: 'Makine Modelleri',
      breadcrumb: [
        {
          text: 'Makine Modelleri',
          to: '/machine_models',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'MACHINE_MODELS_ADD',
      action: 'read',
    },
  },
  {
    path: '/machine_models/edit/:id',
    name: 'MachineModelsEdit',
    component: () => import('@/views/Defines/Machine_models/Edit.vue'),
    meta: {
      pageTitle: 'Makine Modeli',
      breadcrumb: [
        {
          text: 'Makine Modelleri',
          to: '/machine_models',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'MACHINE_MODELS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/machine_types',
    name: 'MachineTypes',
    component: () => import('@/views/Defines/Machine_types/Index.vue'),
    meta: {
      pageTitle: 'Makine Tipleri',
      breadcrumb: [
        {
          text: 'Makine Tipleri',
          active: true,
        },
      ],
      resource: 'MACHINE_TYPES_LIST',
      action: 'read',
    },
  },
  {
    path: '/machine_types/add',
    name: 'MachineTypesAdd',
    component: () => import('@/views/Defines/Machine_types/Add.vue'),
    meta: {
      pageTitle: 'Makine Tipleri',
      breadcrumb: [
        {
          text: 'Makine Tipleri',
          to: '/machine_types',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'MACHINE_TYPES_ADD',
      action: 'read',
    },
  },
  {
    path: '/machine_types/edit/:id',
    name: 'MachineTypesEdit',
    component: () => import('@/views/Defines/Machine_types/Edit.vue'),
    meta: {
      pageTitle: 'Makine Tipi',
      breadcrumb: [
        {
          text: 'Makine Tipleri',
          to: '/machine_types',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'MACHINE_TYPES_EDIT',
      action: 'read',
    },
  },
  {
    path: '/work_types',
    name: 'WorkTypes',
    component: () => import('@/views/Defines/Work_types/Index.vue'),
    meta: {
      pageTitle: 'Çalışma Sınıfları',
      breadcrumb: [
        {
          text: 'Çalışma Sınıfları',
          active: true,
        },
      ],
      resource: 'WORK_TYPES_LIST',
      action: 'read',
    },
  },
  {
    path: '/work_types/add',
    name: 'WorkTypesAdd',
    component: () => import('@/views/Defines/Work_types/Add.vue'),
    meta: {
      pageTitle: 'Çalışma Sınıfları',
      breadcrumb: [
        {
          text: 'Çalışma Sınıfları',
          to: '/work_types',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'WORK_TYPES_ADD',
      action: 'read',
    },
  },
  {
    path: '/work_types/edit/:id',
    name: 'WorkTypesEdit',
    component: () => import('@/views/Defines/Work_types/Edit.vue'),
    meta: {
      pageTitle: 'Çalışma Sınıfı',
      breadcrumb: [
        {
          text: 'Çalışma Sınıfları',
          to: '/work_types',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'WORK_TYPES_EDIT',
      action: 'read',
    },
  },
  {
    path: '/machine_cleanings',
    name: 'MachineCleanings',
    component: () => import('@/views/Defines/Machine_cleanings/Index.vue'),
    meta: {
      pageTitle: 'Makine Temizleme Teknolojileri',
      breadcrumb: [
        {
          text: 'Makine Temizleme Teknolojileri',
          active: true,
        },
      ],
      resource: 'MACHINE_CLEANINGS_LIST',
      action: 'read',
    },
  },
  {
    path: '/machine_cleanings/add',
    name: 'MachineCleaningsAdd',
    component: () => import('@/views/Defines/Machine_cleanings/Add.vue'),
    meta: {
      pageTitle: 'Makine Temizleme Teknolojileri',
      breadcrumb: [
        {
          text: 'Makine Temizleme Teknolojileri',
          to: '/machine_cleanings',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'MACHINE_CLEANINGS_ADD',
      action: 'read',
    },
  },
  {
    path: '/machine_cleanings/edit/:id',
    name: 'MachineCleaningsEdit',
    component: () => import('@/views/Defines/Machine_cleanings/Edit.vue'),
    meta: {
      pageTitle: 'Makine Temizleme Teknolojisi',
      breadcrumb: [
        {
          text: 'Makine Temizleme Teknolojileri',
          to: '/machine_cleanings',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'MACHINE_CLEANINGS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/customer_user_types',
    name: 'CustomerUserTypes',
    component: () => import('@/views/Defines/Customer_user_types/Index.vue'),
    meta: {
      pageTitle: 'Firma Kullanıcı Tipleri',
      breadcrumb: [
        {
          text: 'Firma Kullanıcı Tipleri',
          active: true,
        },
      ],
      resource: 'CUSTOMER_USER_TYPES_LIST',
      action: 'read',
    },
  },
  {
    path: '/customer_user_types/add',
    name: 'CustomerUserTypesAdd',
    component: () => import('@/views/Defines/Customer_user_types/Add.vue'),
    meta: {
      pageTitle: 'Firma Kullanıcı Tipleri',
      breadcrumb: [
        {
          text: 'Firma Kullanıcı Tipleri',
          to: '/customer_user_types',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'CUSTOMER_USER_TYPES_ADD',
      action: 'read',
    },
  },
  {
    path: '/customer_user_types/edit/:id',
    name: 'CustomerUserTypesEdit',
    component: () => import('@/views/Defines/Customer_user_types/Edit.vue'),
    meta: {
      pageTitle: 'Firma Kullanıcı Tipleri',
      breadcrumb: [
        {
          text: 'Firma Kullanıcı Tipleri',
          to: '/customer_user_types',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'CUSTOMER_USER_TYPES_EDIT',
      action: 'read',
    },
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: () => import('@/views/Defines/Jobs/Index.vue'),
    meta: {
      pageTitle: 'İş Tipleri',
      breadcrumb: [
        {
          text: 'İş Tipleri',
          active: true,
        },
      ],
      resource: 'JOBS_LIST',
      action: 'read',
    },
  },
  {
    path: '/jobs/add',
    name: 'JobsAdd',
    component: () => import('@/views/Defines/Jobs/Add.vue'),
    meta: {
      pageTitle: 'İş Tipleri',
      breadcrumb: [
        {
          text: 'İş Tipleri',
          to: '/jobs',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'JOBS_ADD',
      action: 'read',
    },
  },
  {
    path: '/jobs/edit/:id',
    name: 'JobsEdit',
    component: () => import('@/views/Defines/Jobs/Edit.vue'),
    meta: {
      pageTitle: 'İş Tipleri',
      breadcrumb: [
        {
          text: 'İş Tipleri',
          to: '/jobs',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'JOBS_EDIT',
      action: 'read',
    },
  },
]
