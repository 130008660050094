import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    service_request_answers: [],
    service_request_answersCount: 0,
    service_request_answer: {},
    service_request_answerSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getService_request_answers(state) {
      return state.service_request_answers
    },
    getService_request_answersCount(state) {
      return state.service_request_answersCount
    },
    getService_request_answer(state) {
      return state.service_request_answer
    },
    getService_request_answerSaveStatus(state) {
      return state.service_request_answerSave
    },
  },
  mutations: {
    SERVICE_REQUEST_ANSWERS_LIST(state, data) {
      state.service_request_answers = data
    },
    SERVICE_REQUEST_ANSWER_VIEW(state, data) {
      state.service_request_answer = data
    },
    SERVICE_REQUEST_ANSWERS_COUNT(state, data) {
      state.service_request_answersCount = data
    },
    SERVICE_REQUEST_ANSWER_SAVE(state, data) {
      state.service_request_answerSave = data
    },
  },
  actions: {
    service_request_answersList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/service_request_answers', data)
        .then(response => {
          commit('SERVICE_REQUEST_ANSWERS_LIST', response.data.data)
          commit('SERVICE_REQUEST_ANSWERS_COUNT', response.data.count)
          commit('SERVICE_REQUEST_ANSWER_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    service_request_answerView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'service_request_answers.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/service_request_answers', data)
        .then(response => {
          commit('SERVICE_REQUEST_ANSWER_VIEW', response.data.data)
          commit('SERVICE_REQUEST_ANSWER_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    service_request_answerSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/service_request_answers/saveData', data)
        .then(response => {
          commit('SERVICE_REQUEST_ANSWER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
