import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    machine_statuses: [],
    machine_statusesCount: 0,
    machine_status: {},
    machine_statusSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMachine_statuses(state) {
      return state.machine_statuses
    },
    getMachine_statusesCount(state) {
      return state.machine_statusesCount
    },
    getMachine_status(state) {
      return state.machine_status
    },
    getMachine_statusSaveStatus(state) {
      return state.machine_statusSave
    },
  },
  mutations: {
    MACHINE_STATUSES_LIST(state, data) {
      state.machine_statuses = data
    },
    MACHINE_STATUS_VIEW(state, data) {
      state.machine_status = data
    },
    MACHINE_STATUSES_COUNT(state, data) {
      state.machine_statusesCount = data
    },
    MACHINE_STATUS_SAVE(state, data) {
      state.machine_statusSave = data
    },
  },
  actions: {
    machine_statusesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/machine_statuses', data)
        .then(response => {
          commit('MACHINE_STATUSES_LIST', response.data.data)
          commit('MACHINE_STATUSES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    machine_statusView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'machine_statuses.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/machine_statuses', data)
        .then(response => {
          commit('MACHINE_STATUS_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    machine_statusSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/defines/machine_statuses/saveData', data)
        .then(response => {
          commit('MACHINE_STATUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
