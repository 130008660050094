import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    machine_types: [],
    machine_typesCount: 0,
    machine_type: {},
    machine_typeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMachine_types(state) {
      return state.machine_types
    },
    getMachine_typesCount(state) {
      return state.machine_typesCount
    },
    getMachine_type(state) {
      return state.machine_type
    },
    getMachine_typeSaveStatus(state) {
      return state.machine_typeSave
    },
  },
  mutations: {
    MACHINE_TYPES_LIST(state, data) {
      state.machine_types = data
    },
    MACHINE_TYPE_VIEW(state, data) {
      state.machine_type = data
    },
    MACHINE_TYPES_COUNT(state, data) {
      state.machine_typesCount = data
    },
    MACHINE_TYPE_SAVE(state, data) {
      state.machine_typeSave = data
    },
  },
  actions: {
    machine_typesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/machine_types', data)
        .then(response => {
          commit('MACHINE_TYPES_LIST', response.data.data)
          commit('MACHINE_TYPES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    machine_typeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'machine_types.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/machine_types', data)
        .then(response => {
          commit('MACHINE_TYPE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    machine_typeSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/defines/machine_types/saveData', data)
        .then(response => {
          commit('MACHINE_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
