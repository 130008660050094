import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    services: [],
    servicesCount: 0,
    service: {},
    serviceSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getServices(state) {
      return state.services
    },
    getServicesCount(state) {
      return state.servicesCount
    },
    getService(state) {
      return state.service
    },
    getServiceSaveStatus(state) {
      return state.serviceSave
    },
  },
  mutations: {
    SERVICES_LIST(state, data) {
      state.services = data
    },
    SERVICE_VIEW(state, data) {
      state.service = data
    },
    SERVICES_COUNT(state, data) {
      state.servicesCount = data
    },
    SERVICE_SAVE(state, data) {
      state.serviceSave = data
    },
  },
  actions: {
    servicesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/services', data)
        .then(response => {
          commit('SERVICES_LIST', response.data.data)
          commit('SERVICES_COUNT', response.data.count)
          commit('SERVICE_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    serviceView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'services.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/services', data)
        .then(response => {
          commit('SERVICE_VIEW', response.data.data)
          commit('SERVICE_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    serviceSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/services/saveData', data)
        .then(response => {
          commit('SERVICE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    serviceUserSign({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/services/userSign', data)
        .then(response => {
          commit('SERVICE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    serviceCustomerSign({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/services/customerSign', data)
        .then(response => {
          commit('SERVICE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    serviceMail({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post('/services/serviceMail', data)
        .then(response => {
          commit('SERVICE_SAVE', response.data)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
  },
}
