import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    report: {},
  },
  getters: {
    getReport(state) {
      return state.report
    },
  },
  mutations: {
    REPORT_VIEW(state, data) {
      state.report = data
    },
  },
  actions: {
    setReport({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post('/reports/company_reports/ServiceReport', data)
        .then(response => {
          commit('REPORT_VIEW', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },

  },
}
