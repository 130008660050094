import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    customerUsers: [],
    customerUsersCount: 0,
    customerUser: {},
    customerUserSave: {
      message: null,
      status: null,
      id: null,
    },
    customerUserRemove: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getCustomerUsers(state) {
      return state.customerUsers
    },
    getCustomerUsersCount(state) {
      return state.customerUsersCount
    },
    getCustomerUser(state) {
      return state.customerUser
    },
    getCustomerUserSaveStatus(state) {
      return state.customerUserSave
    },
    getCustomerUserRemoveStatus(state) {
      return state.customerUserRemove
    },
  },
  mutations: {
    CUSTOMER_USERS_LIST(state, data) {
      state.customerUsers = data
    },
    CUSTOMER_USER_VIEW(state, data) {
      state.customerUser = data
    },
    CUSTOMER_USERS_COUNT(state, data) {
      state.customerUsersCount = data
    },
    CUSTOMER_USER_SAVE(state, data) {
      state.customerUserSave = data
    },
    CUSTOMER_USER_REMOVE(state, data) {
      state.customerUserRemove = data
    },
  },
  actions: {
    customerUsersList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/customer_users', data)
        .then(response => {
          commit('CUSTOMER_USERS_LIST', response.data.data)
          commit('CUSTOMER_USERS_COUNT', response.data.count)
          commit('CUSTOMER_USER_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    customerUserView({ commit }, id) {
      const data = JSON.stringify({
        select: [
          'customer_users.id AS id',
          'customer_users.name AS name',
          'customer_users.email AS email',
          'customer_users.status AS status',
          'customer_users.id_customer_user_types AS id_customer_user_types',
          'customer_users.id_customers AS id_customers',
        ],
        where: {
          'customer_users.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/customer_users', data)
        .then(response => {
          commit('CUSTOMER_USER_VIEW', response.data.data)
          commit('CUSTOMER_USER_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    customerUserSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/customer_users/saveData', data)
        .then(response => {
          commit('CUSTOMER_USER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    customerUserRemove({ commit }, userId) {
      const data = JSON.stringify({
        id: userId,
      })
      axiosIns
        .post('/customer_users/deleteData', data)
        .then(response => {
          commit('CUSTOMER_USER_REMOVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
