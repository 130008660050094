import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    work_types: [],
    work_typesCount: 0,
    work_type: {},
    work_typeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getWork_types(state) {
      return state.work_types
    },
    getWork_typesCount(state) {
      return state.work_typesCount
    },
    getWork_type(state) {
      return state.work_type
    },
    getWork_typeSaveStatus(state) {
      return state.work_typeSave
    },
  },
  mutations: {
    WORK_TYPES_LIST(state, data) {
      state.work_types = data
    },
    WORK_TYPE_VIEW(state, data) {
      state.work_type = data
    },
    WORK_TYPES_COUNT(state, data) {
      state.work_typesCount = data
    },
    WORK_TYPE_SAVE(state, data) {
      state.work_typeSave = data
    },
  },
  actions: {
    work_typesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/work_types', data)
        .then(response => {
          commit('WORK_TYPES_LIST', response.data.data)
          commit('WORK_TYPES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    work_typeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'work_types.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/work_types', data)
        .then(response => {
          commit('WORK_TYPE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    work_typeSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/defines/work_types/saveData', data)
        .then(response => {
          commit('WORK_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
