import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    invoiced_statuses: [],
    invoiced_statusesCount: 0,
    invoiced_status: {},
    invoiced_statusSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getInvoiced_statuses(state) {
      return state.invoiced_statuses
    },
    getInvoiced_statusesCount(state) {
      return state.invoiced_statusesCount
    },
    getInvoiced_status(state) {
      return state.invoiced_status
    },
    getInvoiced_statusSaveStatus(state) {
      return state.invoiced_statusSave
    },
  },
  mutations: {
    INVOICED_STATUSES_LIST(state, data) {
      state.invoiced_statuses = data
    },
    INVOICED_STATUS_VIEW(state, data) {
      state.invoiced_status = data
    },
    INVOICED_STATUSES_COUNT(state, data) {
      state.invoiced_statusesCount = data
    },
    INVOICED_STATUS_SAVE(state, data) {
      state.invoiced_statusSave = data
    },
  },
  actions: {
    invoiced_statusesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/invoiced_statuses', data)
        .then(response => {
          commit('INVOICED_STATUSES_LIST', response.data.data)
          commit('INVOICED_STATUSES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    invoiced_statusView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'invoiced_statuses.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/invoiced_statuses', data)
        .then(response => {
          commit('INVOICED_STATUS_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    invoiced_statusSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/defines/invoiced_statuses/saveData', data)
        .then(response => {
          commit('INVOICED_STATUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
