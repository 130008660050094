import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    statistics: [],
    services: [],
    serviceRequests: [],
  },
  getters: {
    getStatistics(state) {
      return state.statistics
    },
    getServices(state) {
      return state.services
    },
    getServiceRequests(state) {
      return state.serviceRequests
    },
  },
  mutations: {
    STATISTICS(state, data) {
      state.statistics = data
    },
    SERVICES(state, data) {
      state.services = data
    },
    SERVICE_REQUESTS(state, data) {
      state.serviceRequests = data
    },
  },
  actions: {
    statistics({ commit }) {
      axiosIns
        .post('/dashboard/statistics')
        .then(response => {
          commit('STATISTICS', response.data)
        })
        .catch(error => console.error(error))
    },
    services({ commit }) {
      axiosIns
        .post('/dashboard/services')
        .then(response => {
          commit('SERVICES', response.data)
        })
        .catch(error => console.error(error))
    },
    serviceRequests({ commit }) {
      axiosIns
        .post('/dashboard/services_requests')
        .then(response => {
          commit('SERVICE_REQUESTS', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
