// import axios from '@/libs/axios'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    cities: [],
    citiesCount: 0,
    city: {},
    citySave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getCities(state) {
      return state.cities
    },
    getCitiesCount(state) {
      return state.citiesCount
    },
    getCity(state) {
      return state.city
    },
    getCitySaveStatus(state) {
      return state.citySave
    },
  },
  mutations: {
    CITIES_LIST(state, data) {
      state.cities = data
    },
    CITY_VIEW(state, data) {
      state.city = data
    },
    CITIES_COUNT(state, data) {
      state.citiesCount = data
    },
    CITY_SAVE(state, data) {
      state.citySave = data
    },
  },
  actions: {
    citiesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/cities', data)
        .then(response => {
          commit('CITIES_LIST', response.data.data)
          commit('CITIES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    cityView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'cities.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/cities', data)
        .then(response => {
          commit('CITY_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    citySave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/defines/cities/saveData', data)
        .then(response => {
          commit('CITY_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
