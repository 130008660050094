import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    service_works: [],
    service_worksCount: 0,
    service_work: {},
    service_workSave: {
      message: null,
      status: null,
      id: null,
    },
    service_workDelete: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getService_works(state) {
      return state.service_works
    },
    getService_worksCount(state) {
      return state.service_worksCount
    },
    getService_work(state) {
      return state.service_work
    },
    getService_workSaveStatus(state) {
      return state.service_workSave
    },
  },
  mutations: {
    SERVICE_WORKS_LIST(state, data) {
      state.service_works = data
    },
    SERVICE_WORK_VIEW(state, data) {
      state.service_work = data
    },
    SERVICE_WORKS_COUNT(state, data) {
      state.service_worksCount = data
    },
    SERVICE_WORK_SAVE(state, data) {
      state.service_workSave = data
    },
    SERVICE_WORK_DELETE(state, data) {
      state.service_workDelete = data
    },
  },
  actions: {
    service_worksList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/service_works', data)
        .then(response => {
          commit('SERVICE_WORKS_LIST', response.data.data)
          commit('SERVICE_WORKS_COUNT', response.data.count)
          commit('SERVICE_WORK_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    service_workView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'service_works.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/service_works', data)
        .then(response => {
          commit('SERVICE_WORK_VIEW', response.data.data)
          commit('SERVICE_WORK_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    service_workSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/service_works/saveData', data)
        .then(response => {
          commit('SERVICE_WORK_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    service_workDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/service_works/deleteData', data)
        .then(response => {
          commit('SERVICE_WORK_DELETE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
