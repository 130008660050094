import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    machine_models: [],
    machine_modelsCount: 0,
    machine_model: {},
    machine_modelSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMachine_models(state) {
      return state.machine_models
    },
    getMachine_modelsCount(state) {
      return state.machine_modelsCount
    },
    getMachine_model(state) {
      return state.machine_model
    },
    getMachine_modelSaveStatus(state) {
      return state.machine_modelSave
    },
  },
  mutations: {
    MACHINE_MODELS_LIST(state, data) {
      state.machine_models = data
    },
    MACHINE_MODEL_VIEW(state, data) {
      state.machine_model = data
    },
    MACHINE_MODELS_COUNT(state, data) {
      state.machine_modelsCount = data
    },
    MACHINE_MODEL_SAVE(state, data) {
      state.machine_modelSave = data
    },
  },
  actions: {
    machine_modelsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/machine_models', data)
        .then(response => {
          commit('MACHINE_MODELS_LIST', response.data.data)
          commit('MACHINE_MODELS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    machine_modelView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'machine_models.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/machine_models', data)
        .then(response => {
          commit('MACHINE_MODEL_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    machine_modelSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/defines/machine_models/saveData', data)
        .then(response => {
          commit('MACHINE_MODEL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
