export default [
  {
    path: '/customers',
    name: 'Customers',
    component: () => import('@/views/Customers/Index.vue'),
    meta: {
      pageTitle: 'Müşteri Kartları',
      breadcrumb: [
        {
          text: 'Müşteri Kartları',
          active: true,
        },
      ],
      resource: 'CUSTOMERS_LIST',
      action: 'read',
    },
  },
  {
    path: '/customers/add',
    name: 'CustomersAdd',
    component: () => import('@/views/Customers/Add.vue'),
    meta: {
      pageTitle: 'Müşteri Kartı Ekle',
      breadcrumb: [
        {
          text: 'Müşteri Kartları',
          to: '/customers',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'CUSTOMERS_ADD',
      action: 'read',
    },
  },
  {
    path: '/customers/edit/:id',
    name: 'CustomersEdit',
    component: () => import('@/views/Customers/Edit.vue'),
    meta: {
      pageTitle: 'Müşteri Kartı Güncelle',
      breadcrumb: [
        {
          text: 'Müşteri Kartları',
          to: '/customers',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'CUSTOMERS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/customers/view/:id',
    name: 'CustomersView',
    component: () => import('@/views/Customers/View.vue'),
    meta: {
      pageTitle: 'Müşteri Kartı Görüntüle',
      breadcrumb: [
        {
          text: 'Müşteri Kartları',
          to: '/customers',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'CUSTOMERS_VIEW',
      action: 'read',
    },
  },
]
