import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import customers from './customers'
import customerAddress from './customer_address'
import customerUsers from './customer_users'
import machines from '@/store/machines'
import services from '@/store/services'
import serviceWorks from '@/store/service_works'
import serviceRequests from '@/store/service_requests'
import serviceRequestAnswers from '@/store/service_request_answers'
import cities from '@/store/defines/cities'
import districts from '@/store/defines/districts'
import machineStatuses from '@/store/defines/machine_statuses'
import invoicedStatuses from '@/store/defines/invoiced_statuses'
import machineModels from '@/store/defines/machine_models'
import machineTypes from '@/store/defines/machine_types'
import workTypes from '@/store/defines/work_types'
import machineCleaning from '@/store/defines/machine_cleaning'
import customerUserTypes from '@/store/defines/customer_user_types'
import jobs from '@/store/defines/jobs'
import smtpConfig from '@/store/core/smtpconfig'
import config from '@/store/core/config'
import userTypes from '@/store/core/user_types'
import abilities from '@/store/core/abilities'
import users from '@/store/core/users'
import dashboard from '@/store/dashboard'

// Reports
import customerServiceReport from '@/store/reports/customer_service_report'
import companyReport from '@/store/reports/company_reports'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    customers,
    customerAddress,
    customerUsers,
    machines,
    services,
    serviceWorks,
    serviceRequests,
    serviceRequestAnswers,
    cities,
    districts,
    machineStatuses,
    invoicedStatuses,
    machineModels,
    machineTypes,
    workTypes,
    machineCleaning,
    customerUserTypes,
    jobs,
    smtpConfig,
    config,
    userTypes,
    abilities,
    users,
    dashboard,
    customerServiceReport,
    companyReport,
  },
  strict: process.env.DEV,
})
