import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    config: {},
    configSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getConfig(state) {
      return state.config
    },
    getConfigSaveStatus(state) {
      return state.configSave
    },
  },
  mutations: {
    CONFIG_VIEW(state, data) {
      state.config = data
    },
    CONFIG_SAVE(state, data) {
      state.configSave = data
    },
  },
  actions: {
    configView({ commit }) {
      axiosIns
        .post('/core/config')
        .then(response => {
          commit('CONFIG_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    configSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/core/config/saveData', data)
        .then(response => {
          commit('CONFIG_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
