export default [
  {
    path: '/app/machines',
    name: 'AppMachines',
    component: () => import('@/views/App/Machines/Index.vue'),
    meta: {
      pageTitle: 'Makine Kartları',
      breadcrumb: [
        {
          text: 'Makine Kartları',
          active: true,
        },
      ],
      resource: 'APP_MACHINES_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/machines/view/:id',
    name: 'AppMachineView',
    component: () => import('@/views/App/Machines/View.vue'),
    meta: {
      pageTitle: 'Makine Kartları',
      breadcrumb: [
        {
          text: 'Makine Kartları',
          to: '/app/machines',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'APP_MACHINES_VIEW',
      action: 'read',
    },
  },
  {
    path: '/app/services',
    name: 'AppServices',
    component: () => import('@/views/App/Services/Index.vue'),
    meta: {
      pageTitle: 'Servis Bakım',
      breadcrumb: [
        {
          text: 'Servis Bakım',
          active: true,
        },
      ],
      resource: 'APP_SERVICES_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/services/view/:id',
    name: 'AppServiceView',
    component: () => import('@/views/App/Services/View.vue'),
    meta: {
      pageTitle: 'Servis Bakım',
      breadcrumb: [
        {
          text: 'Servis Bakım',
          to: '/app/services',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'APP_SERVICES_VIEW',
      action: 'read',
    },
  },
  {
    path: '/app/service_requests',
    name: 'AppServiceRequests',
    component: () => import('@/views/App/Service_requests/Index.vue'),
    meta: {
      pageTitle: 'Servis Taleplerim',
      breadcrumb: [
        {
          text: 'Tüm Talepler',
          active: true,
        },
      ],
      resource: 'APP_SERVICE_REQUESTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/service_requests/add/:id',
    name: 'AppServiceRequestAdd',
    component: () => import('@/views/App/Service_requests/Add.vue'),
    meta: {
      pageTitle: 'Servis Talepleri',
      breadcrumb: [
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'APP_SERVICE_REQUESTS_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/service_requests/view/:id',
    name: 'AppServiceRequestView',
    component: () => import('@/views/App/Service_requests/View.vue'),
    meta: {
      pageTitle: 'Servis Talepleri',
      breadcrumb: [
        {
          text: 'Servis Talepleri',
          to: '/app/service_requests',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'APP_SERVICE_REQUESTS_VIEW',
      action: 'read',
    },
  },
]
