import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    machines: [],
    machinesCount: 0,
    machine: {},
    machineSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMachines(state) {
      return state.machines
    },
    getMachinesCount(state) {
      return state.machinesCount
    },
    getMachine(state) {
      return state.machine
    },
    getMachineSaveStatus(state) {
      return state.machineSave
    },
  },
  mutations: {
    MACHINES_LIST(state, data) {
      state.machines = data
    },
    MACHINE_VIEW(state, data) {
      state.machine = data
    },
    MACHINES_COUNT(state, data) {
      state.machinesCount = data
    },
    MACHINE_SAVE(state, data) {
      state.machineSave = data
    },
  },
  actions: {
    machinesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/machines', data)
        .then(response => {
          commit('MACHINES_LIST', response.data.data)
          commit('MACHINES_COUNT', response.data.count)
          commit('MACHINE_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    machineView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'machines.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/machines', data)
        .then(response => {
          commit('MACHINE_VIEW', response.data.data)
          commit('MACHINE_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    machineSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/machines/saveData', data)
        .then(response => {
          commit('MACHINE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
