export default [
  {
    path: '/machines',
    name: 'Machines',
    component: () => import('@/views/Machines/Index.vue'),
    meta: {
      pageTitle: 'Makine Kartları',
      breadcrumb: [
        {
          text: 'Makine Kartları',
          active: true,
        },
      ],
      resource: 'MACHINES_LIST',
      action: 'read',
    },
  },
  {
    path: '/machines/add',
    name: 'MachineAdd',
    component: () => import('@/views/Machines/Add.vue'),
    meta: {
      pageTitle: 'Makine Kartları',
      breadcrumb: [
        {
          text: 'Makine Kartları',
          to: '/machines',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'MACHINES_ADD',
      action: 'read',
    },
  },
  {
    path: '/machines/edit/:id',
    name: 'MachineEdit',
    component: () => import('@/views/Machines/Edit.vue'),
    meta: {
      pageTitle: 'Makine Kartları',
      breadcrumb: [
        {
          text: 'Makine Kartları',
          to: '/machines',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'MACHINES_EDIT',
      action: 'read',
    },
  },
  {
    path: '/machines/view/:id',
    name: 'MachineView',
    component: () => import('@/views/Machines/View.vue'),
    meta: {
      pageTitle: 'Makine Kartları',
      breadcrumb: [
        {
          text: 'Makine Kartları',
          to: '/machines',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'MACHINES_VIEW',
      action: 'read',
    },
  },
]
