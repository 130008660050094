export default [
  {
    path: '/reports/customer-service-report',
    name: 'CustomerServiceReport',
    component: () => import('@/views/Reports/Customer_service_reports/index.vue'),
    meta: {
      pageTitle: 'Müşteri Servis Raporu',
      breadcrumb: [
        {
          text: 'Raporlar',
          active: false,
        },
        {
          text: 'Müşteri Servis Raporu',
          active: true,
        },
      ],
      resource: 'REPORT_CUSTOMER_SERVICE',
      action: 'read',
    },
  },
]
