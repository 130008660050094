import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    machine_cleanings: [],
    machine_cleaningsCount: 0,
    machine_cleaning: {},
    machine_cleaningSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMachine_cleanings(state) {
      return state.machine_cleanings
    },
    getMachine_cleaningsCount(state) {
      return state.machine_cleaningsCount
    },
    getMachine_cleaning(state) {
      return state.machine_cleaning
    },
    getMachine_cleaningSaveStatus(state) {
      return state.machine_cleaningSave
    },
  },
  mutations: {
    MACHINE_CLEANINGS_LIST(state, data) {
      state.machine_cleanings = data
    },
    MACHINE_CLEANING_VIEW(state, data) {
      state.machine_cleaning = data
    },
    MACHINE_CLEANINGS_COUNT(state, data) {
      state.machine_cleaningsCount = data
    },
    MACHINE_CLEANING_SAVE(state, data) {
      state.machine_cleaningSave = data
    },
  },
  actions: {
    machine_cleaningsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/machine_cleaning', data)
        .then(response => {
          commit('MACHINE_CLEANINGS_LIST', response.data.data)
          commit('MACHINE_CLEANINGS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    machine_cleaningView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'machine_cleaning.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/machine_cleaning', data)
        .then(response => {
          commit('MACHINE_CLEANING_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    machine_cleaningSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/defines/machine_cleaning/saveData', data)
        .then(response => {
          commit('MACHINE_CLEANING_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
